import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationError, NavigationStart, NavigationCancel, NavigationSkipped, ChildActivationStart, ChildActivationEnd, ActivationStart, ActivationEnd, Scroll, GuardsCheckStart, GuardsCheckEnd, ResolveStart, ResolveEnd } from '@angular/router';
import { NeoLogService } from '@app/services/neo-log.service';

@Injectable({
  providedIn: 'root'
})
export class NeoRouteHelperService {

constructor(
    private neoLogService: NeoLogService
  ) { }

  public logRouterEvent(prefix: string, event: any): void {
    if (event instanceof NavigationStart) {
      this.neoLogService.log(`${prefix}:Navigation Started: ${event.url}`, event);
    } else if (event instanceof NavigationEnd) {
      this.neoLogService.log(`${prefix}:Navigation Ended: ${event.url}`, event);
    } else if (event instanceof NavigationCancel) {
      this.neoLogService.log(`${prefix}:Navigation Cancelled: ${event.url}`, event);
    } else if (event instanceof NavigationError) {
      this.neoLogService.error(`Navigation Error:`, event);
    } else if (event instanceof ChildActivationStart) {
      this.neoLogService.log(`${prefix}:Child Activation Started:`, event);
    } else if (event instanceof ChildActivationEnd) {
      this.neoLogService.log(`${prefix}:Child Activation Ended:`, event);
    } else if (event instanceof ActivationStart) {
      this.neoLogService.log(`${prefix}:Activation Started:`, event);
    } else if (event instanceof ActivationEnd) {
      this.neoLogService.log(`${prefix}:Activation Ended:`, event);
    } else if (event instanceof Scroll) {
      this.neoLogService.log(`${prefix}:Scroll Event:`, event);
    } else if (event instanceof GuardsCheckStart) {
      this.neoLogService.log(`${prefix}:Guards Check Started:`, event);
    } else if (event instanceof GuardsCheckEnd) {
      this.neoLogService.log(`${prefix}:Guards Check Ended:`, event);
    } else if (event instanceof ResolveStart) {
      this.neoLogService.log(`${prefix}:Resolve Started:`, event);
    } else if (event instanceof ResolveEnd) {
      this.neoLogService.log(`${prefix}:Resolve Ended:`, event);
    } else {
      this.neoLogService.log(`${prefix}:Unknown Router Event:`, event);
    }
  }
}


